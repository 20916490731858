// src/components/Header.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-gray-800 text-white p-6">
      <div className="flex items-center justify-between">
        <Link to="/" className="text-2xl font-bold">
          <img src="/images/image.png" alt="Cyber Stack Logo" className="h-12  inline" />
        </Link>
        <div className="md:hidden">
          {/* Hamburger icon */}
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              ></path>
            </svg>
          </button>
        </div>
        <nav className="hidden md:flex space-x-4">
          <Link to="/" className="hover:text-gray-300">Home</Link>
          <Link to="/about" className="hover:text-gray-300">About</Link>
          <Link to="/services" className="hover:text-gray-300">Services</Link>
          <Link to="/contact" className="hover:text-gray-300">Contact</Link>
          <Link to="/Testpage" classname="hover:text-gray-300">Test Page</Link>
        </nav>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <nav className="md:hidden mt-4 space-y-4">
          <Link to="/" onClick={toggleMenu} className="block hover:text-gray-300">Home</Link>
          <Link to="/about" onClick={toggleMenu} className="block hover:text-gray-300">About</Link>
          <Link to="/services" onClick={toggleMenu} className="block hover:text-gray-300">Services</Link>
          <Link to="/contact" onClick={toggleMenu} className="block hover:text-gray-300">Contact</Link>
          <Link to="/TestPage" onClick={toggleMenu} className='block hover:text-gray-300'>Test Page</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;
