// src/components/TestimonialsSection.js
import React from 'react';

const testimonials = [
  {
    id: 1,
    text: "Cyber Stack's solutions have drastically improved our security posture.",
    author: "Jane Doe, CEO of TechCorp"
  },
  {
    id: 2,
    text: "We feel much safer with Cyber Stack protecting our assets.",
    author: "John Smith, CTO of SecureInc"
  },
  {
    id: 3,
    text: "Their team is professional and dedicated. Highly recommended!",
    author: "Alice Johnson, COO of SafeNet"
  },
];

const TestimonialsSection = () => {
  return (
    <section className="py-16 px-4 bg-gray-100">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">What Our Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map(testimonial => (
            <div key={testimonial.id} className="p-6 bg-white rounded-lg shadow-md">
              <p className="italic mb-4">"{testimonial.text}"</p>
              <p className="font-semibold">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
