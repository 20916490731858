// src/components/CTASection.js
import React from 'react';

const CTASection = () => {
  return (
    <section className="py-16 px-4 bg-blue-600 text-white text-center">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-4xl font-bold mb-4">Ready to Secure Your Future?</h2>
        <p className="mb-8">Contact us today for a free consultation and see how we can help you protect your assets.</p>
        <button className="px-6 py-3 bg-white text-blue-600 rounded-lg hover:bg-gray-200 transition">
          Get Started
        </button>
      </div>
    </section>
  );
};

export default CTASection;
