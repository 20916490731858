// src/pages/TempPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasAccessCookie } from '../utils/checkCookie';

const TempPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasAccessCookie('userAccess')) {
      navigate('/404');
    }
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen p-6 bg-gray-100">
      <h1 className="text-5xl font-bold text-gray-800">Protected Content</h1>
      <p className="text-lg text-gray-600 mt-4 text-center">
        Only accessible if the access cookie is set.
      </p>
    </div>
  );
};

export default TempPage;
