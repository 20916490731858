// src/components/HeroSection.js
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import serverImage from '../assets/img/server.jpg'; // Corrected path to the image

const HeroSection = () => {
  return (
    <HeroContainer>
      <h1>Cyber Stack Security</h1>
      <p>DEFENCE BEYOND BOUNDARIES</p>
      <motion.button whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        Get Started
      </motion.button>
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(${serverImage});
  background-size: cover;
  color: white;
  text-align: center;
  h1 {
    font-size: 4rem;
    font-weight: bold;
  }
  p {
    font-size: 1.5rem;
    margin: 20px 0;
  }
  button {
    padding: 10px 30px;
    font-size: 1.2rem;
    background-color: #3d5afe;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
`;

export default HeroSection;
