// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Components
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import ServicesSection from './components/ServicesSection';
import TestimonialsSection from './components/TestimonialsSection';
import CTASection from './components/CTASection';
import Footer from './components/Footer';

// Pages
import ContactPage from './pages/ContactPage';
import ServicesPage from './pages/ServicesPage';  
import AboutPage from './pages/AboutPage';
import TempPage from './pages/TempPage';
import NotFoundPage from './pages/NotFoundPage';

// Utility function for checking cookie
import { hasAccessCookie } from './utils/checkCookie';

const App = () => {
  const cookieName = 'grantAccess'; // Replace this with the actual cookie name

  // Protected route wrapper component
  const ProtectedRoute = ({ children }) => {
    return hasAccessCookie(cookieName) ? children : <Navigate to="/404" replace />;
  };

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          {/* Home page */}
          <Route 
            path="/" 
            element={
              <>
                <HeroSection />
                <AboutSection />
                <ServicesSection />
                <TestimonialsSection />
                <CTASection />
              </>
            } 
          />

          {/* Protected Page */}
          <Route 
            path="/TempPage" 
            element={
              <ProtectedRoute>
                <TempPage />
              </ProtectedRoute>
            } 
          />

          {/* Other pages */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/services" element={<ServicesPage />} />

          {/* 404 Page */}
          <Route path="/404" element={<NotFoundPage />} />
          
          {/* Catch-all route for any non-existent pages */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
