// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import App from './App';
import './index.css'; // Make sure to include your global styles

// Get the root element
const rootElement = document.getElementById('root');

// Create a root
const root = createRoot(rootElement); 

// Render the App
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
