// src/components/AboutSection.js
import React from 'react';

const AboutSection = () => {
  return (
    <section className="py-16 px-4 bg-gray-100">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">About Us</h2>
        <p className="text-lg mb-8">
          Cyber Stack is dedicated to providing advanced cybersecurity solutions that protect your digital assets and ensure your peace of mind. With our expertise, we deliver tailored strategies to keep your business safe from emerging threats.
        </p>
        <div className="flex justify-center space-x-4">
          <button className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition">
            Learn More
          </button>
          <button className="px-6 py-2 border border-blue-600 text-blue-600 rounded-lg hover:bg-blue-600 hover:text-white transition">
            Our Solutions
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
