// src/pages/AboutPage.js

import React from 'react';
import { FaShieldAlt, FaUsers, FaAward } from 'react-icons/fa';
import { motion } from 'framer-motion';

const AboutPage = () => {
  return (
    <div className="bg-white py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        
        {/* Section 1 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-24">
          <motion.img 
            src="https://www.nccoe.nist.gov/sites/default/files/styles/712x534_webp/public/2021-09/MDSwithPhones%20%281%29.jpg.webp?itok=PUT0oBil" 
            alt="Our Mission" 
            className="rounded-lg shadow-lg w-full h-auto"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-5xl font-bold text-gray-900 mb-4">Our Mission</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              Our mission is to provide top-tier cybersecurity solutions that empower businesses to defend against the most complex threats in the digital world.
            </p>
          </motion.div>
        </div>

        {/* Section 2 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-24">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-5xl font-bold text-gray-900 mb-4">Our Vision</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              We envision a world where businesses operate safely and securely in a rapidly evolving technological landscape, without fear of cyber threats.
            </p>
          </motion.div>
          <motion.img 
            src="https://www.nccoe.nist.gov/sites/default/files/styles/712x534_webp/public/2021-09/iStock-1252719266.jpg.webp?itok=2vVJ6Rq_" 
            alt="Our Vision" 
            className="rounded-lg shadow-lg w-full h-auto"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
        </div>

        {/* Section 3 */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-24">
          <motion.img 
            src="https://www.nccoe.nist.gov/sites/default/files/styles/712x534_webp/public/2021-09/iStock-147456329.jpg.webp?itok=IhplFBVF" 
            alt="Our Values" 
            className="rounded-lg shadow-lg w-full h-auto"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          />
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-5xl font-bold text-gray-900 mb-4">Our Values</h2>
            <p className="text-lg text-gray-600 leading-relaxed">
              Integrity, innovation, and excellence define who we are. We strive to provide superior cybersecurity solutions with a customer-centric focus.
            </p>
          </motion.div>
        </div>


        {/* Icon Section */}
        <div className="text-center mb-24">
          <h3 className="text-3xl font-bold text-gray-900 mb-10">Why Choose Us</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            
            {/* Icon 1 */}
            <div className="flex flex-col items-center">
              <FaShieldAlt className="text-6xl text-indigo-500 mb-4" />
              <h4 className="text-xl font-semibold mb-2">Top-Notch Security</h4>
              <p className="text-gray-600 text-center">
                We offer the most reliable security systems to keep your data and infrastructure safe.
              </p>
            </div>
            
            {/* Icon 2 */}
            <div className="flex flex-col items-center">
              <FaUsers className="text-6xl text-indigo-500 mb-4" />
              <h4 className="text-xl font-semibold mb-2">Expert Team</h4>
              <p className="text-gray-600 text-center">
                Our team consists of cybersecurity experts with years of experience in the field.
              </p>
            </div>
            
            {/* Icon 3 */}
            <div className="flex flex-col items-center">
              <FaAward className="text-6xl text-indigo-500 mb-4" />
              <h4 className="text-xl font-semibold mb-2">Award-Winning Service</h4>
              <p className="text-gray-600 text-center">
                Recognized globally for our excellent customer service and industry-leading solutions.
              </p>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
};

export default AboutPage;
