// src/components/ServicesSection.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faLock, faChartBar } from '@fortawesome/free-solid-svg-icons';

const services = [
  { 
    id: 1, 
    title: 'Consultation', 
    description: 'We offer expert consultations to assess your current security posture and recommend tailored solutions to enhance your protection.', 
    icon: faShieldAlt 
  },
  { 
    id: 2, 
    title: 'Implementation', 
    description: 'Our team specializes in implementing robust security measures that safeguard your digital infrastructure against potential threats.', 
    icon: faLock 
  },
  { 
    id: 3, 
    title: 'Monitoring', 
    description: 'Continuous monitoring of your systems ensures that any unusual activities are detected and addressed promptly, minimizing risks.', 
    icon: faChartBar 
  },
];

const ServicesSection = () => {
  return (
    <section className="py-16 px-4 bg-white">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Our Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {services.map(service => (
            <div key={service.id} className="p-6 bg-gray-100 rounded-lg shadow-md transition-transform transform hover:scale-105">
              <div className="text-6xl mb-4 flex justify-center text-blue-600">
                <FontAwesomeIcon icon={service.icon} />
              </div>
              <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
              <p className="text-gray-700">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
