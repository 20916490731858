import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
      <p className="text-lg text-gray-600 mb-8 text-center">
        Oops! The page you’re looking for doesn’t exist or is restricted.
      </p>
      <button
        onClick={() => navigate('/')}
        className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-md hover:bg-blue-500 transition duration-200"
      >
        Go to Home
      </button>
    </div>
  );
};

export default NotFoundPage;
