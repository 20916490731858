// src/components/Footer.js
import React from 'react';
import { FaTwitter, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8 text-center">
      <div className="flex justify-center space-x-8 mb-4">
        <a href="https://x.com/CyberStackSec" className="text-2xl hover:text-blue-500">
          <FaTwitter />
        </a>
        <a href="https://linkedin.com/company/cyberstacksec" className="text-2xl hover:text-blue-500">
          <FaLinkedin />
        </a>
      </div>
      <p className="text-sm">&copy; 2024 Cyber Stack. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
