// src/pages/ServicesPage.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faSearch, faCrosshairs, faDesktop } from '@fortawesome/free-solid-svg-icons';

const services = [
  {
    title: 'Network Security',
    description: 'Protect your network with robust security measures and 24/7 monitoring.',
    icon: faShieldAlt,
  },
  {
    title: 'Vulnerability Assessment',
    description: 'Identify weaknesses before attackers do, with detailed reports and fixes.',
    icon: faSearch,
  },
  {
    title: 'Penetration Testing',
    description: 'Simulate real-world attacks to test your defenses against cyber threats.',
    icon: faCrosshairs,
  },
  {
    title: 'Endpoint Protection',
    description: 'Ensure every device on your network is safeguarded with top-tier protection.',
    icon: faDesktop,
  },
];

const ServicesPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <div className="container mx-auto py-16">
        <h1 className="text-5xl font-bold text-center mb-12 text-gray-800">
          Our Services
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-8 bg-white rounded-lg shadow-lg hover:shadow-xl transform hover:scale-105 transition-transform duration-300"
            >
              <div className="flex justify-center mb-6">
                <FontAwesomeIcon
                  icon={service.icon}
                  className="text-blue-600 h-16 w-16"
                />
              </div>
              <h2 className="text-2xl font-semibold text-center mb-4">
                {service.title}
              </h2>
              <p className="text-center text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
